/**
 * @file monitor.js
 * 商品选购公共的部分可在public/src/pages/common/business-monitor/common.js添加
 * metricPageSuccess页面核心接口是否正常响应、metricCccClick组件点击
 * --------
 * 首页导航独有的客户端监控模块可在此文件添加
 */

import { SIMetric } from 'public/src/pages/common/monitor/index'

// params: { metric_name: '', tags: { page: '', status: 1|0 }, message: '' }

/**
 * 组件过滤
 * tags.page：页面名称
 * tags.component_type：过滤的组件类型style
 * tags.position: 组件的位置
 * tags.reason: 过滤原因
 *  */ 
const metricCompFilter = (params) => {
  SIMetric.metricCount({ metric_name: 'ccc_component_be_filtered_total', ...params })
}

/**
 * 分类页类目点击
 * tags.page：页面名称
 * tags.cate_level: 左边一级分类报1 右边二级分类报 2
 *  */ 
const metricCategoryClick = (params) => {
  SIMetric.metricCount({ metric_name: 'category_click_total', ...params })
}

const metricCategoryType = (params) => {
  SIMetric.metricCount({ metric_name: 'category_click_type_total', ...params })
}

/**
 * 全局弹窗队列监控埋点
 * tags.track_type：埋点类型(dq_dialog_add:2 | dq_dialog_show:3 | dq_dialog_close:4)
 * tags.alert_type：弹窗类型名
 *  */ 
const metricGlobPopup = (params) => {
  SIMetric.metricCount({ metric_name: 'home_alert_queue_track_total', ...params })
}


/**
 * 监控首页导航客户端warn日志的统计数
 * tags.message(string)
 *  */ 
const metricWarnSlsCount = (params) => {
  SIMetric.metricCount({ metric_name: 'web_client_home_warn_total', ...params })
}

/**
 * 监控首页导航客户端error日志的统计数
 * tags.message(string)
 *  */ 
const metricErrorSlsCount = (params) => {
  SIMetric.metricCount({ metric_name: 'web_client_home_error_total', ...params })
}


export default {
  metricCompFilter,
  metricCategoryClick,
  metricCategoryType,
  metricGlobPopup,
  metricWarnSlsCount,
  metricErrorSlsCount,
}
